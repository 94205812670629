import $ from 'jquery';

export default function clickLink() {
  
  $( document ).ready(function() {
    if ($('div[data-link]').length > 0) {
      $(document).on('click', '.js-link-detail-disabled', function (e){
        e.stopPropagation();
      });
  
      $(document).on('click', 'div[data-link]', function (){
        const link = $(this).attr('data-link');
  
        if(link){
          window.location.href = link;
        }
      });
    }
  });
}